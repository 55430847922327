body {
  margin: 0;
  font-family:
    -apple-system,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  height: 60px;
  width: auto;
}

.right-container {
  width: 78vw;
  height: 100%;
  float: right;
}

.loading-container {
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.required::after {
  content: " *";
  color: red;
  position: absolute;
  margin-left: -15px;
  margin-top: 10px;
}

.error-class p {
  color: red;
}

.loading {
  position: fixed;
  display: inline-block;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 35%;
}

.loading::after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #f0718d;
  border-color: #f0718d transparent #f0718d transparent;
  animation: loading 1.2s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
