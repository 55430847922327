.slot-booked,
.slot-not-booked {
  margin: 10px;
  padding: 5px 10px;
}

.slot-booked {
  border: none;
  color: grey;
  border-radius: 5px;
}

.slot-not-booked {
  border: none;
  border-radius: 5px;
  color: blue;
  background-color: lightblue;
}

.slot-not-booked:hover {
  background-color: lightgreen;
  border: none;
  color: white;
  border-radius: 5px;
}
