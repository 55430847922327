
.left-container {
  position: relative;
  width: 20vw;
  height: 100%;
  float: left;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  background-color: #f2f2f2;
  background-attachment: fixed;
  z-index: 1;
}

.sidebar {
  position: relative;
}

.sidebar a {
  padding: 20px;
  display: block;
  font-size: 20px;
  color: black;
  text-decoration: none;
}

.selected {
  text-decoration: none;
  font-size: 25px;
  background-color: #f0718d;
}
